import React from "react";
import PhoneLineup from "../assets/iPhone16Pro-lineup.jpg"
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NewiPhoneRelease: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="mx-2">
            <h1 style={{ alignSelf: 'center' }} className="mt-4 text-center">iPhone 16 and InventoryWatch</h1>
            <h6 style={{ alignSelf: 'center' }} className="text-muted text-center">
                Here's what you can expect from InventoryWatch before, during, and after the in-store release of this year's iPhones.
            </h6>
            <img className="my-4" alt="iPhone 16 Pro lineup" src={PhoneLineup} />
            <Container className="mw-100" style={{ width: '800px' }}>
                <div>
                    <h5>Will InventoryWatch support this year's iPhone lineup?</h5>
                    <p>
                        Yup! InventoryWatch will offer support for all of the new products
                        Apple announced this fall, including:
                    </p>
                    <ul>
                        <li>iPhone 16</li>
                        <li>iPhone 16 Plus</li>
                        <li>iPhone 16 Pro</li>
                        <li>iPhone 16 Pro Max</li>
                    </ul>
                </div>
                <div>
                    <h5>When will support for these products be added?</h5>
                    <p>
                        Support for these products will go live no later than Thursday,
                        September 19th, 2024. That's one day before the in-store release of
                        this year's iPhone, and should allow InventoryWatch users plenty
                        of time to set up searches and notifications for their desired
                        product.
                    </p>
                    <p>
                        Once support for these products is added, we'll announce the change
                        via an email to all InventoryWatch subscribers, so start your subscription
                        today!
                    </p>
                </div>
                <div>
                    <h5>What can I expect on Friday, September 20th?</h5>
                    <p>
                        On the day this year's products become available in
                        Apple Stores (Sept. 20), InventoryWatch will start providing data to
                        users about which stores have products available for in-store pickup.
                        If you've enabled notifications for your product search, either via email
                        or text message (for Premium subscribers), you'll be notified with updates
                        on product availability.
                    </p>
                </div>
                <div>
                    <h5>Can InventoryWatch help me pre-order an iPhone 16?</h5>
                    <p>
                        Not at this time. InventoryWatch sources its inventory data directly from
                        Apple, and Apple does not provide data about which stores will have stock
                        on launch day ahead of time. As soon as Apple makes that data available on
                        launch day, you'll be notified!
                    </p>
                </div>
                <div>
                    <h5>Should I subscribe to InventoryWatch before launch day?</h5>
                    <p>
                        Definitely! In order to have your searches and notifications set up
                        properly by Sept. 20, you should purchase your InventoryWatch subscription
                        ahead of time.
                    </p>
                    {/* <p>
                        And great news: to celebrate the iPhone 16 launch, all of our
                        annual plans are on sale! From now until the end of September, get
                        <b> 30% off when you subscribe for a year.</b> Subscribe today!
                    </p> */}
                </div>
                <div className="mt-2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        className="mb-4"
                        variant="outline-primary"
                        style={{ alignSelf: 'center' }}
                        onClick={e => {
                            e.preventDefault()
                            navigate(`/plans`)
                        }}
                    >
                        Sign up for InventoryWatch
                    </Button>
                    {/* <h4 className="text-center" style={{ alignSelf: 'center' }}>Get <b>30% off</b> all annual plans through the end of September!</h4> */}
                </div>
            </Container>
        </div>
    )
}

export default NewiPhoneRelease
