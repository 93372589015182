import React from "react";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <div style={{
        minHeight: 16,
        flex: 1
      }} />
      <p
        style={{
          display: "flex",
          flexDirection: 'column',
          alignItems: "center",
          flexShrink: 0,
          padding: 24,
          lineHeight: 0.9
        }}
      >
        <small>Copyright 2024, <mark><a style={{ textDecoration: 'none' }} href='https://ruggedapps.net' target="_blank" rel="noreferrer">Rugged Apps</a></mark></small>
        <br />
        <small><a href="mailto:support@inventorywatch.app">support@inventorywatch.app</a></small>
        <br />
        <small><a href="" onClick={() => { navigate(`/tos`) }}>Terms of Service</a> | <a href="" onClick={() => { navigate(`/privacy-policy`) }}>Privacy Policy</a> | <a href="" onClick={() => { navigate(`/refund-policy`) }}>Refund Policy</a></small>
        <br />
        <small><em>InventoryWatch is not affiliated with Apple Inc.</em></small>
      </p>
    </>
  )
}

export default Footer
