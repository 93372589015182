import React from "react";
import Select from 'react-select'
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useProductList } from "../services/products.service";
import { useStores } from "../services/stores.service";
import { addLocationToStoreName } from "../types/SearchStore";

export interface NewSearch {
    product_type?: string,
    store_id?: string,
}

export interface NewSearchModalProps {
    show: boolean,
    isAddingNewSearch: boolean,
    errorMessage: string | undefined | null
    onHide: () => void
    onSubmit: (newSearch: NewSearch) => void
}

const NewSearchModal: React.FC<NewSearchModalProps> = (props) => {
    const [newSearch, updateNewSearch] = React.useState<NewSearch>({})

    const productListQuery = useProductList()
    const storeListQuery = useStores()

    const dismiss = () => {
        updateNewSearch({ product_type: undefined, store_id: undefined })
        props.onHide()
    }

    return (
        <Modal
            show={props.show}
            onHide={dismiss}
            aria-labelledby="contained-modal-title-vcenter"
            fullscreen="md-down"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Create New Search
                </Modal.Title>
            </Modal.Header>
            <Form style={{ padding: 16 }}>
                <Form.Group className="form-group">
                    <Form.Label>Select a product:</Form.Label>
                    <Select
                        name="product-type-select"
                        options={productListQuery.data}
                        getOptionLabel={product => product.product_name}
                        getOptionValue={product => product.product_id}
                        isLoading={productListQuery.isLoading}
                        onChange={value => {
                            updateNewSearch({ ...newSearch, product_type: value?.product_id })
                        }}
                    />
                </Form.Group>
                <Form.Group className="form-group">
                    <Form.Label>Select a store:</Form.Label>
                    <Select
                        name="store-select"
                        options={storeListQuery.data}
                        getOptionLabel={store => addLocationToStoreName(store.name, store.city, store.state_code)}
                        getOptionValue={store => store.id}
                        filterOption={(option, input) => {
                            return option.label.toLowerCase().includes(input.toLowerCase()) ||
                                (option.data.state_name || '').toLowerCase().includes(input.toLowerCase())
                        }}
                        isLoading={storeListQuery.isLoading}
                        onChange={value => {
                            updateNewSearch({ ...newSearch, store_id: value?.id })
                        }}
                    />
                    {!!props.errorMessage && (
                        <Form.Text style={{ color: `red` }}>Error: {props.errorMessage}</Form.Text>
                    )}

                </Form.Group>
                <Row>
                    <Col >
                        <Button onClick={dismiss} disabled={props.isAddingNewSearch} style={{ float: "right" }} variant="light" type="button">
                            Cancel
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button
                            disabled={!newSearch.product_type || !newSearch.store_id || props.isAddingNewSearch}
                            variant="primary"
                            type="submit"
                            onClick={() => {
                                if (!newSearch.product_type || !newSearch.store_id) return
                                props.onSubmit(newSearch)
                            }}
                        >
                            {
                                props.isAddingNewSearch ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: 8 }}
                                        />
                                        Creating...
                                    </>
                                ) : (
                                    'Create search'
                                )
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default NewSearchModal
