import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AccountData, AccountUpdateData } from '../types/AccountData';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useMyAccount = () => {
    const auth = useAuth0()

    return useQuery(`myAccount`, async () => {
        const accessToken = await auth.getAccessTokenSilently()

        try {
            const { data } = await axios.get<AccountData>(
                `${apiServerUrl}/v2/account`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            return data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
                throw error
            } else {
                console.log('unexpected error: ', error);
                throw new Error('An unexpected error occurred')
            }
        }
    })
}

export const useUpdateMyAccount = () => {
    const queryClient = useQueryClient()
    const auth = useAuth0()

    return useMutation(
        async (account: AccountUpdateData) => {
            const accessToken = await auth.getAccessTokenSilently()

            try {
                const { data } = await axios.patch<AccountData>(
                    `${apiServerUrl}/v2/account`,
                    account,
                    {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )

                return data
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.log('error message: ', error.message);
                    throw error
                } else {
                    console.log('unexpected error: ', error);
                    throw new Error('An unexpected error occurred')
                }
            }
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(`myAccount`, data)
            }
        }
    )
}
