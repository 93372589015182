export interface SearchStore {
    name: string,
    id: string,

    country: string,
    city: string,
    state_code: string | null,
    state_name: string | null,
    address1: string | null,
    address2: string | null,
    postal_code: string,

    telephone: string,
    store_slug: string,
}

export function addLocationToStoreName(name: string, city: string | null, state: string | null): string {
    return `${name} | ${city}${(!!state) ? ` , ${state}` : ``}`
}
