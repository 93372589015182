import React, { useEffect } from 'react';
import { Accordion, Badge, Breadcrumb, Card, Col, Container, ListGroup, Row, Spinner, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { useInventory } from '../services/inventory.service';
import { useProductList } from '../services/products.service';
import { useDeleteSearch, useSearch, useSearchSettingsUpdate } from '../services/searches.service';
import { useStores as useStoresList } from '../services/stores.service';
import imageForProduct from '../components/ProductImage';
import SearchSettings from '../components/SearchSettings';
import axios from 'axios';
import { Search } from '../types/Search';

const SearchPage: React.FC = () => {
    const params = useParams()
    const navigate = useNavigate()
    const searchId = params.searchId;

    const searchQuery = useSearch(searchId)
    const inventoryQuery = useInventory(searchId)
    const storesQuery = useStoresList()
    const productListQuery = useProductList()

    const searchSettingsMutation = useSearchSettingsUpdate(searchId)
    const deleteSearch = useDeleteSearch()

    const onDeleteSearch = () => {
        deleteSearch.mutate(searchId, {
            onSettled: (data, error, variables, context) => {
                if (!!error) {
                    console.log(error)
                }

                navigate('/')
            }
        })
    }

    const onUpdateSearchSettings = (search: Search) => {
        searchSettingsMutation.mutate(search)
    }

    const productId = searchQuery.data?.product_type
    const ProductImage = (imageForProduct(productId))

    const storesWithInventory = inventoryQuery.data?.stores || []
    const store = {
        name: searchQuery.data?.store_name,
        city: searchQuery.data?.store_city,
        state: searchQuery.data?.store_state
    }

    // TODO: this page needs better error-handling 
    useEffect(() => {
        if (!!searchQuery.error) console.log(searchQuery.error)
        if (axios.isAxiosError(searchQuery.error) && searchQuery.error.response?.status === 403) {
            navigate("/")
        }
    }, [searchQuery.error, navigate])

    return (
        <Container>
            <Breadcrumb listProps={{ className: 'm-1 mt-2' }}>
                <Breadcrumb.Item href="/">My Searches</Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {
                        searchQuery.isLoading ? (<>Loading...</>) : (
                            <>{searchQuery.data?.product_name}</>)
                    }
                </Breadcrumb.Item>
            </Breadcrumb>

            <Stack direction="horizontal">
                <ProductImage style={{ width: 82, height: 82 }} />
                <Stack direction='vertical' style={{ paddingTop: 16 }}>
                    <h3 style={{ lineHeight: 0.8 }}>
                        {
                            searchQuery.isLoading ? (<>Loading...</>) : (
                                <>{searchQuery.data?.product_name}</>)
                        }
                    </h3>
                    <h5 style={{ paddingLeft: 2 }}>
                        {
                            !searchQuery.isLoading && (
                                <>near {store.name} <small className="text-secondary">| {store.city}{store.state ? `, ${store.state}` : ``}</small></>
                            )
                        }
                    </h5>
                </Stack>
            </Stack>
            <Accordion className='d-block d-lg-none'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Search Settings</Accordion.Header>
                    <Accordion.Body>
                        <SearchSettings
                            isDeleting={deleteSearch.isLoading}
                            deleteSearch={onDeleteSearch}
                            isSaving={searchSettingsMutation.isLoading}
                            productListQuery={productListQuery}
                            storeListQuery={storesQuery}
                            isLoading={searchQuery.isLoading}
                            searchSettings={searchQuery.data}
                            updateSettings={onUpdateSearchSettings}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Row>
                <Col lg={7}>
                    {
                        (inventoryQuery.isLoading || (inventoryQuery.isFetching && inventoryQuery.data?.stores.length === 0)) && (
                            <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Spinner style={{ marginTop: 144 }} />
                            </div>
                        )
                    }
                    {
                        !inventoryQuery.isLoading && (
                            storesWithInventory.length > 0
                                ? (
                                    storesWithInventory.map((store) => (
                                        <div key={store.store_id} style={{ paddingTop: 16 }}>
                                            <h5 className='mb-0'>{store.store_name}</h5>
                                            <h6><small>{store.store_city || ``}{!!store.store_state ? `, ${store.store_state}` : ``}</small></h6>
                                            {store.available_models.length === 0 ? (<p><i>No models available</i></p>) : (
                                                <ListGroup className='pb-3'>
                                                    {store.available_models.map(model => (

                                                        <ListGroup.Item className="d-flex justify-content-between" key={model.part_number}>
                                                            <div>{model.display_name}</div>
                                                            {
                                                                model.is_preferred && (
                                                                    <div>
                                                                        <Badge className="d-md-block d-none" bg="primary" pill>Preferred</Badge>
                                                                        <Badge className="d-block d-md-none" bg="primary" pill>♥︎</Badge>
                                                                    </div>
                                                                )
                                                            }
                                                        </ListGroup.Item>

                                                    ))}
                                                </ListGroup>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}>
                                        <p className='mt-5'><em>No inventory found</em></p>
                                    </div>
                                )

                        )
                    }
                </Col>
                <Col lg={5} className="d-lg-block d-none">
                    <Card>
                        <Card.Header as="h5">Search Settings</Card.Header>
                        <Card.Body>
                            <SearchSettings
                                isDeleting={deleteSearch.isLoading}
                                deleteSearch={onDeleteSearch}
                                isSaving={searchSettingsMutation.isLoading}
                                productListQuery={productListQuery}
                                storeListQuery={storesQuery}
                                isLoading={searchQuery.isLoading}
                                searchSettings={searchQuery.data}
                                updateSettings={onUpdateSearchSettings}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>
    )
}

export default SearchPage;
