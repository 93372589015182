import React, { useEffect, useState } from "react";
import faqContentUrl from "../assets/faq.md"
import Markdown from "react-markdown";
import { Button, Container } from "react-bootstrap";

export const SupportFAQPage: React.FC = () => {
    const [faqText, setFaqText] = useState(``)

    useEffect(() => {
        fetch(faqContentUrl)
            .then(r => r.text())
            .then(t => setFaqText(t))
            .catch(e => {
                console.error(e)
                setFaqText(`Failed to load FAQ text. Please contact support.`)
            })
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Container className="mw-100" style={{ width: '700px' }}>
                <div className="mb-4">
                    <h2 className="mt-4 text-center">Frequently Asked Questions</h2>
                    <h6 className="text-muted text-center">
                        If you're having trouble using InventoryWatch, check out some answers to common questions below.
                    </h6>
                </div>

                <Markdown>{faqText}</Markdown>

                <div className="my-4 text-center">
                    <h6 >Still having trouble? Feel free to reach out to support.</h6>
                    <a href='mailto:support@inventorywatch.app'>
                        <Button variant="primary">support@inventorywatch.app</Button>
                    </a>
                </div>
            </Container>
        </div>
    )
}
