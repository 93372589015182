import axios from 'axios';
import { useQuery } from "react-query";
import { Product } from "../types/Product";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useProductList = () => useQuery({
    queryKey: ['productList'],
    queryFn: fetchProductList
})

export async function fetchProductList(): Promise<Product[]> {
    try {
        const { data } = await axios.get<Product[]>(
            `${apiServerUrl}/v2/products`,
            {
                headers: {
                    Accept: 'application/json',
                }
            }
        )

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}
