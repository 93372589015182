import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import axios from 'axios';
import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from 'react-query';
import { NewSearch } from '../components/NewSearchModal';
import { CreateSearch, Search } from '../types/Search';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useMySearches = () => {
    const auth = useAuth0()
    return useQuery('mySearchesData', () => fetchSearches(auth), {
        retry: (count, error) => {
            if (count > 3) {
                return false
            }

            if (axios.isAxiosError(error)) {
                return error.response?.status !== 403
            }

            return true
        }
    })
}

async function fetchSearches(auth: Auth0ContextInterface<User>): Promise<Search[]> {
    const accessToken = await auth.getAccessTokenSilently()

    try {
        const { data } = await axios.get<Search[]>(
            `${apiServerUrl}/v2/searches/my_searches`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}

export const useSearch = (searchId: string | undefined) => {
    const auth = useAuth0()

    const searchQuery = useQuery({
        queryKey: ['searchData', searchId],
        queryFn: (context) => fetchSearch(context, auth)
    })

    return searchQuery
}

async function fetchSearch(context: QueryFunctionContext, auth: Auth0ContextInterface<User>) {
    const accessToken = await auth.getAccessTokenSilently()

    try {
        const searchId = context.queryKey[1]

        const { data } = await axios.get<Search>(
            `${apiServerUrl}/v2/searches/${searchId}`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}

export const useSearchSettingsUpdate = (searchId: string | undefined) => {
    const queryClient = useQueryClient()
    const auth = useAuth0()

    const updateSettingsMutation = useMutation(
        async (settings: Search | undefined) => {
            if (!settings) return

            const accessToken = await auth.getAccessTokenSilently()
            const response = await axios.patch<Search>(`${apiServerUrl}/v2/searches/${searchId}`, settings, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            })

            return response.data
        },
        {
            onMutate: () => {
                queryClient.setQueryData(['inventoryForSearch', searchId], { id: searchId, stores: [] })
            },
            onSuccess: (data) => {
                queryClient.setQueryData([`searchData`, searchId], data)
            },
            onSettled: () => {
                queryClient.invalidateQueries(['inventoryForSearch', searchId])
                queryClient.invalidateQueries('mySearchesData')
            }
        }
    )

    return updateSettingsMutation
}

export const useAddSearch = () => {
    const queryClient = useQueryClient()
    const auth = useAuth0()

    const addSearchMutation = useMutation(
        async (newSearch: NewSearch) => {
            try {
                const accessToken = await auth.getAccessTokenSilently()
                return await axios.post<Search>(`${apiServerUrl}/v2/searches`, newSearch, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${accessToken}`
                    }
                })
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.log('error message: ', error.message);
                    throw error
                } else {
                    console.log('unexpected error: ', error);
                    throw new Error('An unexpected error occurred')
                }
            }
        },
        {
            onSettled: () => queryClient.invalidateQueries('mySearchesData')
        }
    )

    return addSearchMutation
}

export const useDeleteSearch = () => {
    const queryClient = useQueryClient()
    const auth = useAuth0()

    const addSearchMutation = useMutation(async (searchId: string | undefined) => {
        try {
            const accessToken = await auth.getAccessTokenSilently();
            return axios.delete<CreateSearch>(`${apiServerUrl}/v2/searches/${searchId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            })
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
                throw error
            } else {
                console.log('unexpected error: ', error);
                throw new Error('An unexpected error occurred')
            }
        }
    },
        {
            onSettled: () => queryClient.invalidateQueries('mySearchesData')
        }
    )

    return addSearchMutation
}
