import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

export const AppError: React.FC = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const errorMessage = searchParams.get("error_message") || "An unknown error occurred."

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs="12" md="6" lg="5" xl="4" className="pricing-col">
                    <Card>
                        <Card.Header>
                            InventoryWatch Error
                        </Card.Header>
                        <Card.Body>
                            <p>
                                {errorMessage}
                            </p>
                            <Button onClick={() => { navigate("/account") }}>Go to My Account</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
