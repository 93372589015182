import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import { Auth0ProviderWithHistory } from './auth0-provider-with-history';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AppError } from './pages/AppError';
import { CallbackPage } from './pages/CallbackPage';
import CheckoutFailedPage from './pages/CheckoutFailedPage';
import CheckoutSuccessPage from './pages/CheckoutSuccessPage';
import { ForceLogin } from './pages/ForceLogin';
import { MyAccount } from './pages/MyAccount';
import MySearches from './pages/MySearches';
import { PlansPage } from './pages/PricingPage';
import SearchPage from './pages/SearchPage';
import DownloadAppPage from './pages/DownloadApp';
import NewiPhoneRelease from './pages/NewiPhoneRelease';
import { SupportFAQPage } from './pages/SupportFAQPage';
import { TermsOfService } from './pages/TermsOfService';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { RefundPolicy } from './pages/RefundPolicy';

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path='/' element={<App />}>
            <Route index element={<MySearches />} />
            <Route
              path='search/:searchId'
              element={<ProtectedRoute component={SearchPage} />}
            />
            <Route
              path='account'
              element={<ProtectedRoute component={MyAccount} />}
            />
            <Route path='plans' element={<PlansPage />} />
            <Route path='download' element={<DownloadAppPage />} />
            <Route path='callback' element={<CallbackPage />} />
            <Route path='checkout_complete' element={<CheckoutSuccessPage />} />
            <Route path='checkout_cancelled' element={<CheckoutFailedPage />} />
            <Route path='error' element={<AppError />} />
            <Route path='force_login' element={<ForceLogin />} />
            <Route path='new-iphone' element={<NewiPhoneRelease />} />
            <Route path='support' element={<SupportFAQPage />} />
            <Route path='tos' element={<TermsOfService />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='refund-policy' element={<RefundPolicy />} />
          </Route>
        </Routes>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  )
}

export default AppRouter;
