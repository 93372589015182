import axios from 'axios';
import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { QueryFunctionContext, useQuery } from 'react-query';
import { InventoryResult } from '../types/InventoryResult';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useInventory = (searchId: string | undefined) => {
    const auth = useAuth0()

    const inventoryQuery = useQuery({
        queryKey: ['inventoryForSearch', searchId],
        queryFn: (context) => fetchInventory(context, auth),
        refetchInterval: (1000 * 60) // refetch every minute
    })

    return inventoryQuery
}

async function fetchInventory(context: QueryFunctionContext, auth: Auth0ContextInterface<User>) {
    const accessToken = await auth.getAccessTokenSilently()

    try {
        const searchId = context.queryKey[1]

        const { data } = await axios.get<InventoryResult>(
            `${apiServerUrl}/v2/searches/${searchId}/inventory`,
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`
                }
            }
        )

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}

export const useExampleInventory = () => useQuery({
    queryKey: [`exampleInventory`],
    queryFn: fetchExampleInventory
})

interface ExampleInventory {
    product_type: string,
    inventory: {
        store_id: string,
        store_name: string,
        product_count: string
    }[]
}

const fetchExampleInventory = async (): Promise<ExampleInventory[]> => {
    try {
        const { data } = await axios.get<ExampleInventory[]>(
            `${apiServerUrl}/v2/inventory/example_inventory`,
            {
                headers: {
                    Accept: 'application/json',
                }
            }
        )

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}
