import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SelectableProduct from "../components/SelectableProduct";
import { useExampleInventory } from "../services/inventory.service";
import { useProductList } from "../services/products.service";
import { useCountries, useStores } from "../services/stores.service";
import logo from "../assets/inventorywatch-marketing-hero.jpg"
import HomepageStore from "../components/HomepageStore";

const Homepage: React.FC = () => {
    const navigate = useNavigate()
    const { loginWithRedirect } = useAuth0()
    const [selectedProduct, setSelectedProduct] = useState('')
    const productListQuery = useProductList()
    const storeListQuery = useStores()
    const countryListQuery = useCountries()
    const exampleInventoryQuery = useExampleInventory()

    const selectedStores = !!exampleInventoryQuery.data ? exampleInventoryQuery.data.find(value => value.product_type === selectedProduct)?.inventory : null

    useEffect(() => {
        if (!!exampleInventoryQuery.data && selectedProduct === '') {
            setSelectedProduct(exampleInventoryQuery.data[0].product_type)
        }
    }, [exampleInventoryQuery.data, selectedProduct])

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: "/",
            }
        })
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div className="mb-4">
                <h1 className="text-center mt-4">
                    Stop waiting weeks for Apple products to ship.
                </h1>
                <div className="container-full col-md-12 mt-4 mb-4">
                    <img
                        alt="Depicts the macOS client app, the web app on an iPhone, and an Apple Watch showing an inventory notification"
                        className="center-block full-width img-responsive portfolio-box"
                        src={logo}
                    />
                </div>
                <h5 className="text-center mt-3">
                    InventoryWatch monitors Apple Store inventory, and notifies you when the model you want is available nearby.
                </h5>
                <h6 className="text-center mt-3">
                    Available for macOS and on the web (for subscribers).
                </h6>
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
                <Button variant="outline-primary" onClick={() => navigate('/plans')}>Sign up</Button>
                <Button variant="outline-primary" onClick={handleLogin}>Login</Button>
            </div>
            <Button className="mt-2" variant="primary" onClick={() => navigate('/download')}>Download for macOS</Button>

            <p className="m-1"><em>or</em></p>
            <p><strong>Check out some example inventory:</strong></p>
            <div className="border rounded mb-4" style={{ overflow: 'auto' }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 8, minWidth: 0 }}>
                    {
                        !!exampleInventoryQuery.data && exampleInventoryQuery.data.map(product => {
                            return (
                                <SelectableProduct key={product.product_type} product_type={product.product_type} selected_product_type={selectedProduct} on_select={setSelectedProduct} />
                            )
                        })
                    }
                </div>
            </div>
            <h4 className="mb-4">{productListQuery.data?.find(p => p.product_id === selectedProduct)?.product_name ?? ''}</h4>
            <ul
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, 244px",
                    justifyContent: "center",
                    gap: 16,
                    alignSelf: "stretch"
                }}
            >
                {
                    !!selectedStores && selectedStores.map(store => {
                        const fullStore = storeListQuery.data?.find(s => s.id === store.store_id)
                        if (!fullStore) return null

                        const country = countryListQuery.data?.find(c => fullStore.country === c.locale_code)
                        if (!country) return null

                        return (
                            <HomepageStore key={store.store_id} store={fullStore} country={country} productCount={store.product_count} />
                        )
                    })
                }
            </ul>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                <div className="m-4">
                    <p><strong>Product support</strong></p>
                    <ul>
                        {
                            !!productListQuery?.data && productListQuery.data.map(product => {
                                return (
                                    <li key={product.product_id}>{product.product_name}</li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="m-4">
                    <p><strong>Country support</strong></p>
                    <ul>
                        {
                            !!countryListQuery?.data && countryListQuery.data.map(country => {
                                return (
                                    <li key={country.country_code}>{country.display_name}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default Homepage;
