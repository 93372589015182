import { areSearchProductModelsIdentical, SearchProductModel } from "./SearchProductModel"

export interface Search {
    search_id: string,
    product_type: string,
    product_name: string,
    store_id: string,
    store_name: string,
    store_city: string,
    store_state: string | null | undefined,
    stores_with_inventory: number | null
    include_nearby_store_results: boolean,
    show_results_only_for_preferred_models: boolean,
    send_text_updates: boolean,
    send_email_updates: boolean,
    product_models: SearchProductModel[]
}

export interface CreateSearch {
    product_id: string,
    store_id: string,
}

export const areSearchesIdentical = (first: Search | null | undefined, second: Search | null | undefined): boolean => {
    if (!first) return !second
    if (!second) return !first

    return (
        first.search_id === second.search_id &&
        first.product_type === second.product_type &&
        first.product_name === second.product_name &&
        first.store_id === second.store_id &&
        first.store_name === second.store_name &&
        first.stores_with_inventory === second.stores_with_inventory &&
        first.include_nearby_store_results === second.include_nearby_store_results &&
        first.show_results_only_for_preferred_models === second.show_results_only_for_preferred_models &&
        areSearchProductModelsIdentical(first.product_models, second.product_models)
    )
}

export const generateInventoryPreviewText = (search: Search): string => {
    return `${search.show_results_only_for_preferred_models ? `Preferred models found` : `Inventory available`} at ${search.stores_with_inventory ?? 0} ${!search.show_results_only_for_preferred_models ? `nearby` : ``} store${(search.stores_with_inventory ?? 0) === 1 ? '' : 's'}`

}
