import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

export interface DeleteSearchProps {
    deleteSearch: () => void,
    showDeleteModal: boolean,
    isDeleting: boolean,
    setShowDeleteModal: (value: React.SetStateAction<boolean>) => void
}

const DeleteSearchModal: React.FC<DeleteSearchProps> = ({ deleteSearch, showDeleteModal, setShowDeleteModal, isDeleting }) => {
    return (
        <Modal centered size="sm" show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Body>Are you sure you want to delete this search?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" disabled={isDeleting} onClick={() => setShowDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" type="submit" disabled={isDeleting} onClick={() => deleteSearch()}>
                    {
                        isDeleting ? (
                            <>
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: 8 }}
                                />
                                Deleting...
                            </>
                        ) : (
                            'Delete search'
                        )
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSearchModal;
