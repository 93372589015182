import React from 'react';
import { Button, Col, Row, Spinner, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAddSearch, useDeleteSearch, useMySearches } from '../services/searches.service';
import { generateInventoryPreviewText, Search } from '../types/Search';
import NewSearchModal, { NewSearch } from '../components/NewSearchModal';
import { useAuth0 } from '@auth0/auth0-react';
import Homepage from './Homepage';
import DeleteSearchModal from '../components/DeleteSearchModal';
import MySearchCard from '../components/MySearchCard';
import { AxiosError } from 'axios';


const MySearches: React.FC = () => {
    const { isAuthenticated, isLoading: isLoadingAuth } = useAuth0()

    const { isLoading, error, data } = useMySearches()
    const [modalError, setModalError] = React.useState<string | null | undefined>(undefined)
    const [modalShow, setModalShow] = React.useState(false)

    const [searchIdToDelete, setSearchIdToDelete] = React.useState<string | null>(null)

    const navigate = useNavigate()
    const navigateToSearch = (search: Search) => navigate(`/search/${search.search_id}`)

    const addNewSearch = useAddSearch()
    const onAddSearch = (newSearch: NewSearch) => {
        addNewSearch.mutate(newSearch, {
            onSettled: (response, error, variables, context) => {
                if (!!error) {
                    const errorData = (error as AxiosError).response?.data as { reason?: string, message?: string } | null | undefined
                    const reason = errorData?.reason || errorData?.message || `unknown`
                    setModalError(reason)
                }

                const search = response?.data
                if (!search) {
                    // TODO: show a toast or something
                    console.error(new Error(`api did not return new search; status code ${response?.status}`))
                    return
                }

                navigateToSearch(search)
            }
        })
    }

    const deleteSearch = useDeleteSearch()
    const onDeleteSearch = (searchId: string) => {
        deleteSearch.mutate(searchId, {
            onSettled: (data, error, variables, context) => {
                if (!!error) {
                    console.log(error)
                }

                setSearchIdToDelete(null)
            }
        })
    }

    const LoadingState = () => (
        <Row className="justify-content-md-center">
            <Col md="auto" style={{ padding: 64 }}>
                <Spinner />
            </Col>
        </Row>
    )

    // TODO: this error state sucks 
    const ErrorState = (error: any) => (
        <Row className="justify-content-md-center">
            <Col md="auto" style={{ padding: 64 }}>
                An error occurred when attempting to fetch this resource. Please confirm you have an active subscription.
            </Col>
        </Row>
    )

    if (isLoadingAuth) {
        return LoadingState()
    }

    return (
        <>
            {!isAuthenticated && (
                <Homepage /> // TODO: probably should just handle this with routing
            )}
            {isAuthenticated &&
                (
                    <div>
                        <Stack style={{ paddingLeft: 16, paddingTop: 16, paddingRight: 16 }} direction='horizontal'>
                            <h1>My Searches</h1>
                            {
                                (!error) && (
                                    <>
                                        <Button onClick={() => { setModalError(undefined); setModalShow(true); }} className='ms-auto d-lg-block d-none'>Create new search</Button>
                                        <Button onClick={() => { setModalError(undefined); setModalShow(true); }} className='ms-auto d-block d-lg-none fw-bold fs-5'>+</Button>
                                    </>
                                )
                            }

                        </Stack>

                        {
                            (!!error) && (ErrorState(error))
                        }

                        {
                            isLoading ? (
                                LoadingState()
                            ) : (
                                !!data && (
                                    // TODO: this ternary logic is *trash*
                                    data.length === 0 ? (
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <p style={{ marginTop: 144 }}><em>Add a search!</em></p>
                                        </div>
                                    ) : (
                                        <Row xs={1} md={2} lg={3} style={{ paddingLeft: 16, paddingRight: 16 }}>
                                            {data.map(search => {
                                                return (
                                                    <Col key={search.search_id} style={{ padding: 12 }}>
                                                        <MySearchCard
                                                            search={search}
                                                            navigateToSearch={navigateToSearch}
                                                            setSearchIdToDelete={setSearchIdToDelete}
                                                            generateInventoryPreviewText={generateInventoryPreviewText}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    )
                                )
                            )
                        }

                        <NewSearchModal
                            show={modalShow}
                            isAddingNewSearch={addNewSearch.isLoading}
                            errorMessage={modalError}
                            onHide={() => setModalShow(false)}
                            onSubmit={onAddSearch}
                        />

                        <DeleteSearchModal
                            showDeleteModal={searchIdToDelete !== null}
                            isDeleting={deleteSearch.isLoading}
                            deleteSearch={() => {
                                if (searchIdToDelete === null) return
                                onDeleteSearch(searchIdToDelete)
                            }}
                            setShowDeleteModal={(show) => { setSearchIdToDelete(show ? searchIdToDelete : null) }}
                        />
                    </div>
                )
            }
        </>
    )
}

export default MySearches;
