import React from "react";

import { SearchStore } from "../types/SearchStore";
import { Country } from "../types/Country";
import { Card } from "react-bootstrap";

export interface HomepageStoreProps {
    store: SearchStore,
    country: Country,
    productCount: string
}
const HomepageStore: React.FC<HomepageStoreProps> = (props) => {
    let locationString: string
    if (!!props.store.state_code) {
        locationString = `${props.store.city}, ${props.store.state_code}`
    } else {
        locationString = `${props.store.city}, ${props.country.country_code}`
    }

    return (
        <li style={{ listStyleType: "none" }} key={props.store.id} >
            <Card>
                <Card.Body>
                    <Card.Title className="mb-1">{props.store.name}</Card.Title>
                    <Card.Subtitle className="mb-2"><small>{locationString}</small></Card.Subtitle>
                    <Card.Text>
                        {props.productCount === "1" ? `${props.productCount} type` : `${props.productCount} types`} available
                    </Card.Text>
                </Card.Body>
            </Card>
        </li>
    )
}

export default HomepageStore;
