import React from "react";
import logo from "../assets/InventoryWatchMacOS.png"
import screenshot from "../assets/InventoryWatchMacOSScreenshot_shadow.png"
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";

const DownloadAppPage: React.FC = () => {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1 style={{ padding: 16 }}>Download InventoryWatch for macOS</h1>
                <a href='https://raw.githubusercontent.com/Rugged-Apps/InventoryWatch-Mac-Releases/main/Releases/InventoryWatch.dmg' download>
                    <Button >Download for free</Button>
                </a>
            </div>
            <img alt="Depicts the InventoryWatch app for macOS" src={screenshot} />
            <Container className="border rounded" style={{ backgroundColor: "#f8f8f8", width: "auto" }}>
                <Row className="justify-content-center" style={{ padding: 16, paddingTop: 0 }}>
                    <Col xs="auto" className="pricing-col">
                        <Card>
                            <Card.Body style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <h5>Find Apple Store inventory <em>fast</em> with our macOS app</h5>
                                <img alt="InventoryWatch for macOS app icon" src={logo} width={128} height={128} />
                            </Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    🆓 Download and add one search for <strong>free</strong>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    ✅ Login to your InventoryWatch account to add unlimited searches
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    💻 Get inventory notifications on your Mac
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    💨 Faster inventory updates, updating every five minutes
                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Body style={{ padding: 0, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <a href='https://raw.githubusercontent.com/Rugged-Apps/InventoryWatch-Mac-Releases/main/Releases/InventoryWatch.dmg' download>
                                    <Button className="mt-3">Download for free</Button>
                                </a>
                                <p className="mt-2"><em>Requires macOS Ventura (13.0)</em></p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DownloadAppPage;
