import axios from 'axios';
import { useQuery } from 'react-query';
import { Country } from '../types/Country';
import { SearchStore } from '../types/SearchStore';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const useStores = () => useQuery({
    queryKey: ['storesList'],
    queryFn: fetchStoreList
})

async function fetchStoreList(): Promise<SearchStore[]> {
    try {
        const { data } = await axios.get<any[]>(
            `${apiServerUrl}/v2/stores`,
            {
                headers: {
                    Accept: 'application/json',
                }
            }
        )

        return data.map(value => {
            return {
                id: value.store_id,
                name: value.store_name,
                country: value.country_locale,
                city: value.city,
                state_code: value.state_code,
                state_name: value.state_name,
                address1: value.address1,
                address2: value.address2,
                postal_code: value.postal_code,
                telephone: value.telephone,
                store_slug: value.store_slug,
            }
        })
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}

export const useCountries = () => useQuery({
    queryKey: ['countriesList'],
    queryFn: fetchCountryList
})

async function fetchCountryList(): Promise<Country[]> {
    try {
        const { data } = await axios.get<Country[]>(
            `${apiServerUrl}/v2/countries`,
            {
                headers: {
                    Accept: 'application/json',
                }
            }
        )

        return data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error
        } else {
            console.log('unexpected error: ', error);
            throw new Error('An unexpected error occurred')
        }
    }
}
