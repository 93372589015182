import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import Cookies from 'js-cookie'
import { Button, Col, Container, Row } from "react-bootstrap";
import { PricingCard } from "../components/PricingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface CheckoutSessionResponse {
    checkout_url: string | null
}

// TODO: this could get extracted somewhere 
const startCheckout = async (lookupKey: string, auth0Id?: string) => {
    const response = await axios.post<CheckoutSessionResponse>(`${process.env.REACT_APP_API_SERVER_URL}/v2/account/create-checkout-session`, {
        lookup_key: lookupKey,
        auth0_id: auth0Id
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    })

    const { checkout_url } = response.data
    if (!checkout_url) {
        console.log('error! no checkout url')
        return
    }

    window.location.href = checkout_url
}

export const PlansPage: React.FC = () => {
    const auth = useAuth0()
    const auth0Id = auth.user?.sub

    const billingPreference = (Cookies.get(`app.inventorywatch.pricing_isBilledAnnually`) || `true`) === `true`
    const [isBilledAnnually, setIsBilledAnnually] = React.useState(billingPreference)

    React.useEffect(() => {
        Cookies.set(`app.inventorywatch.pricing_isBilledAnnually`, `${isBilledAnnually}`)
    }, [isBilledAnnually])

    return (
        <div>
            <h1 style={{ padding: 16, textAlign: 'center', marginTop: 2 }}>Pricing</h1>
            <Container className="border rounded" style={{ backgroundColor: "#f8f8f8", width: "auto" }}>
                <Row className="justify-content-center" style={{ paddingTop: 16 }}>
                    <Col xs="auto" className="px-1">
                        <Button
                            variant={isBilledAnnually ? "primary" : "outline-primary"}
                            onClick={e => {
                                e.preventDefault()
                                setIsBilledAnnually(true)
                            }}
                        >
                            Billed annually {isBilledAnnually && (<FontAwesomeIcon className="ms-2" icon={faCheck} style={{ color: "#ffffff", }} />)}
                        </Button>
                    </Col>
                    <Col xs="auto" className="px-1">
                        <Button
                            variant={isBilledAnnually ? "outline-primary" : "primary"}
                            onClick={e => {
                                e.preventDefault()
                                setIsBilledAnnually(false)
                            }}
                        >
                            Billed monthly {!isBilledAnnually && (<FontAwesomeIcon className="ms-2" icon={faCheck} style={{ color: "#ffffff", }} />)}
                        </Button>
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ padding: 16, paddingTop: 0 }}>
                    <Col xs="auto" className="pricing-col">
                        <PricingCard
                            title="Good"
                            isBilledAnnually={isBilledAnnually}
                            monthlyCost={0}
                            annualCost={0}
                            saleAnnualCost={null}
                            monthlyStripeKey={process.env.REACT_APP_STRIPE_KEY_BASIC_MONTHLY!}
                            yearlyStripeKey={process.env.REACT_APP_STRIPE_KEY_BASIC_YEARLY!}
                            auth0Id={auth0Id}
                            startCheckout={startCheckout}
                            numberOfSearches={1}
                            numberOfSearchesText="one"
                            hasProductTrackFeature={true}
                            hasNoAdsFeature={true}
                            hasEmailNotificationFeature={false}
                            hasTextNotificationsFeature={false}
                        />
                    </Col>
                    <Col xs="auto" className="pricing-col">
                        <PricingCard
                            title="Better"
                            isBilledAnnually={isBilledAnnually}
                            monthlyCost={7}
                            annualCost={30}
                            saleAnnualCost={null}
                            monthlyStripeKey={process.env.REACT_APP_STRIPE_KEY_BASIC_MONTHLY!}
                            yearlyStripeKey={process.env.REACT_APP_STRIPE_KEY_BASIC_YEARLY!}
                            auth0Id={auth0Id}
                            startCheckout={startCheckout}
                            numberOfSearches={5}
                            numberOfSearchesText="five"
                            hasProductTrackFeature={true}
                            hasNoAdsFeature={true}
                            hasEmailNotificationFeature={true}
                            hasTextNotificationsFeature={false}
                        />
                    </Col>
                    <Col xs="auto" className="pricing-col">
                        <PricingCard
                            title="Best"
                            isBilledAnnually={isBilledAnnually}
                            monthlyCost={10}
                            annualCost={45}
                            saleAnnualCost={null}
                            monthlyStripeKey={process.env.REACT_APP_STRIPE_KEY_PREMIUM_MONTHLY!}
                            yearlyStripeKey={process.env.REACT_APP_STRIPE_KEY_PREMIUM_YEARLY!}
                            auth0Id={auth0Id}
                            startCheckout={startCheckout}
                            numberOfSearches={10}
                            numberOfSearchesText="ten"
                            hasProductTrackFeature={true}
                            hasNoAdsFeature={true}
                            hasEmailNotificationFeature={true}
                            hasTextNotificationsFeature={true}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
