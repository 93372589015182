import React, { useEffect, useState } from "react";
import contentUrl from "../assets/privacy-policy.md"
import Markdown from "react-markdown";
import { Button, Container } from "react-bootstrap";

export const PrivacyPolicy: React.FC = () => {
    const [ppText, setPpText] = useState(``)

    useEffect(() => {
        fetch(contentUrl)
            .then(r => r.text())
            .then(t => setPpText(t))
            .catch(e => {
                console.error(e)
                setPpText(`Failed to load text. Please contact support.`)
            })
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Container className="mw-100" style={{ width: '700px' }}>
                <div className="mb-4" hidden={true}>
                    <h2 className="mt-4 text-center">Terms of Service</h2>
                </div>

                <Markdown className="my-4">{ppText}</Markdown>

                <div className="my-4 text-center">
                    <h6>Have questions? Feel free to reach out to support.</h6>
                    <a href='mailto:support@inventorywatch.app'>
                        <Button variant="primary">support@inventorywatch.app</Button>
                    </a>
                </div>
            </Container>
        </div>
    )
}
