import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const ForceLogin: React.FC = () => {

    const { loginWithRedirect } = useAuth0()

    React.useEffect(() => {
        loginWithRedirect({
            appState: {
                returnTo: "/",
            },
        })
    }, [loginWithRedirect])

    return (
        <></>
    )
}
