import React from "react";
import imageForProduct from "./ProductImage";

interface SelectableProductProps {
    product_type: string,
    selected_product_type: string,
    on_select: (product_type: string) => void
}

const SelectionIndicator: React.FC = () => {
    return (
        <div style={{
            display: "inline-block",
            width: "24px",
            height: "4px",
            borderRadius: 2,
            background: "black",
        }} />
    )
}

const SelectableProduct: React.FC<SelectableProductProps> = ({ product_type, selected_product_type, on_select }) => {
    const ProductImage = (imageForProduct(product_type))
    const isSelected = selected_product_type === product_type

    return (
        <div
            onClick={() => { on_select(product_type) }}
            style={{ cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}
        >
            <ProductImage style={{ width: 82, height: 82 }} />
            {
                isSelected && (<SelectionIndicator />)
            }
        </div>
    )
}

export default SelectableProduct;
