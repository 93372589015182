import React from "react";
import { Search } from "../types/Search";
import { Button, Card, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

export interface MySearchCardProps {
    search: Search,
    navigateToSearch: (search: Search) => void,
    setSearchIdToDelete: (value: string | null) => void,
    generateInventoryPreviewText: (search: Search) => string
}

const MySearchCard: React.FC<MySearchCardProps> = ({ search, navigateToSearch, setSearchIdToDelete, generateInventoryPreviewText }) => {
    return (
        <Card onClick={() => navigateToSearch(search)}>
            <Card.Header>
                <h6 className='mb-0 d-flex justify-content-between align-items-center'>
                    {search.store_name}
                    <div onClick={(e) => { e.stopPropagation(); }}>
                        <Dropdown>
                            <Dropdown.Toggle variant="link" style={{ textDecoration: 'none' }} bsPrefix="p-0">
                                <FontAwesomeIcon icon={faEllipsis} style={{ color: "#000000", }} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { setSearchIdToDelete(search.search_id) }}>Delete search</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </h6>

            </Card.Header>
            <Card.Body>
                <Card.Title>{search.product_name}</Card.Title>
                <Card.Text>
                    {generateInventoryPreviewText(search)}
                </Card.Text>
                <Button variant="outline-primary" onClick={(e) => { e.stopPropagation(); navigateToSearch(search) }}>View inventory</Button>
            </Card.Body>
        </Card>
    )
}

export default MySearchCard;
