import React from 'react';

import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router';
import Footer from './components/Footer';
import NavigationBar from './components/NavigationBar';

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MainPage />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

const MainPage: React.FC = () => {
  return (
    <Container className="p-3 vh-100 d-flex flex-column">
      <NavigationBar />
      <Outlet />
      <Footer />
    </Container>
  )
}

export default App;
