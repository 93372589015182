import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// taken liberally from: https://developer.auth0.com/resources/guides/spa/react/basic-authentication

// TODO: typescript?
export const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate()

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri
      }}
    >
      {children}
    </Auth0Provider>
  );
};
