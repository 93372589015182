import React, { useCallback, useEffect } from "react";
import Select, { MultiValue } from 'react-select'
import { Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { UseQueryResult } from "react-query";
import { addLocationToStoreName, SearchStore } from "../types/SearchStore";
import { Product } from "../types/Product";
import { SearchProductModel } from "../types/SearchProductModel";
import { Search } from "../types/Search";
import DeleteSearchModal from "./DeleteSearchModal";
import { useMyAccount } from "../services/account.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

export interface SearchSettingsProps {
    isLoading: boolean,
    isSaving: boolean,
    isDeleting: boolean,
    storeListQuery: UseQueryResult<SearchStore[], unknown>,
    productListQuery: UseQueryResult<Product[], unknown>
    searchSettings: Search | undefined | null,
    updateSettings: (search: Search) => void
    deleteSearch: () => void
}

const SearchSettings: React.FC<SearchSettingsProps> = (props: SearchSettingsProps) => {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false)
    const [invalidNotification, setInvalidNotification] = React.useState(false)

    const accountQuery = useMyAccount()

    const checkValidNotificationState = useCallback(() => {
        const hasNotification = !!props.searchSettings?.send_text_updates || !!props.searchSettings?.send_email_updates
        const hasPreferredModels = !!(props.searchSettings?.product_models.filter(model => model.is_preferred).length !== 0)

        setInvalidNotification(hasNotification && !hasPreferredModels)
    }, [props.searchSettings, setInvalidNotification])

    const updatePreferredModels = (selectedValues: MultiValue<SearchProductModel>) => {
        if (!props.searchSettings || props.isSaving) return

        const allModels = props.searchSettings.product_models
        const preferredModels = new Set(selectedValues.map(v => v.part_number))

        for (let i = 0; i < allModels.length; i++) {
            let model = allModels[i]
            model.is_preferred = preferredModels.has(model.part_number)

            allModels[i] = model
        }

        props.updateSettings({ ...props.searchSettings, product_models: allModels })
        checkValidNotificationState()
    }

    useEffect(() => {
        checkValidNotificationState()
    }, [props.searchSettings, checkValidNotificationState])

    if (props.isLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center" }} >
                <Spinner />
            </div>
        )
    }

    return (
        <Form>
            <Form.Group className="form-group">
                <Form.Label>Product Type</Form.Label>
                <Select
                    name="product-type-select"
                    options={props.productListQuery.data}
                    getOptionLabel={product => product.product_name}
                    getOptionValue={product => product.product_id}
                    isDisabled={props.productListQuery.isLoading || props.isSaving}
                    value={
                        !props.searchSettings ? null :
                            { product_name: props.searchSettings.product_name, product_id: props.searchSettings.product_type }
                    }
                    onChange={value => {
                        if (!props.searchSettings || !value) return
                        props.updateSettings({
                            ...props.searchSettings,
                            product_name: value.product_name,
                            product_type: value.product_id
                        })
                    }}
                />
                <Form.Label className="form-label-top-space">Preferred Models</Form.Label>
                <Select
                    isMulti
                    name="preferred-models-select"
                    options={props.searchSettings?.product_models}
                    getOptionLabel={option => option.display_name}
                    getOptionValue={option => option.part_number}
                    value={
                        !props.searchSettings?.product_models ? null : props.searchSettings.product_models.filter(model => model.is_preferred)
                    }
                    onChange={updatePreferredModels}
                    closeMenuOnSelect={false}
                />
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>Preferred Store</Form.Label>
                <Select
                    name="preferred-store-select"
                    isDisabled={props.storeListQuery.isLoading || props.isSaving}
                    options={props.storeListQuery.data}
                    getOptionLabel={store => addLocationToStoreName(store.name, store.city, store.state_code)}
                    getOptionValue={store => store.id}
                    value={
                        !props.searchSettings ? null : {
                            name: props.searchSettings.store_name,
                            id: props.searchSettings.store_id,
                            city: props.searchSettings.store_city,
                            state_code: props.searchSettings.store_state
                        } as { name: string, id: string, city: string, state_code: string | null }
                    }
                    onChange={newStore => {
                        if (!props.searchSettings || !newStore) return
                        props.updateSettings({
                            ...props.searchSettings,
                            store_id: newStore.id,
                            store_name: newStore.name
                        })
                    }}
                />
                <Form.Check
                    style={{ paddingTop: 8 }}
                    type="checkbox"
                    id="include_nearby_store_results"
                    label="Include results from nearby stores"
                    checked={props.searchSettings?.include_nearby_store_results || false}
                    disabled={!props.searchSettings || props.isSaving}
                    onChange={(e) => {
                        if (!props.searchSettings) return
                        props.updateSettings({ ...props.searchSettings, include_nearby_store_results: e.target.checked })
                    }}
                />
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Check
                    type="switch"
                    id="show_results_only_for_preferred_models"
                    label="Only include results for preferred models"
                    checked={props.searchSettings?.show_results_only_for_preferred_models || false}
                    disabled={!props.searchSettings || props.isSaving}
                    onChange={(e) => {
                        if (!props.searchSettings) return
                        props.updateSettings({ ...props.searchSettings, show_results_only_for_preferred_models: e.target.checked })
                    }}
                />
            </Form.Group>
            <Form.Group className="form-group">
                <Form.Label>Notifications</Form.Label>
                <OverlayTrigger key={'text-notification-overlay'} placement="top" overlay={
                    (accountQuery.data?.text_notifications_allowed || false) ? (<></>) : (
                        <Tooltip id={`tooltip-top`}>
                            Text notifications are only allowed for <strong>Premium</strong> subscribers.
                        </Tooltip>
                    )
                }>
                    <div>
                        <Form.Check
                            type="switch"
                            id="send_text_updates"
                            label="Send inventory notifications via text"
                            checked={(accountQuery.data?.text_notifications_allowed || false) && (props.searchSettings?.send_text_updates || false)}
                            disabled={!(accountQuery.data?.text_notifications_allowed || false) || (!props.searchSettings || props.isSaving)}
                            onChange={(e) => {
                                if (!props.searchSettings) return
                                props.updateSettings({ ...props.searchSettings, send_text_updates: e.target.checked })
                            }}
                            onClick={(e) => {
                                if (!(accountQuery.data?.text_notifications_allowed || false)) {

                                }
                            }}
                        />
                    </div>
                </OverlayTrigger>
                <Form.Check
                    type="switch"
                    id="send_email_updates"
                    label="Send inventory notifications via email"
                    checked={props.searchSettings?.send_email_updates || false}
                    disabled={!props.searchSettings || props.isSaving}
                    onChange={(e) => {
                        if (!props.searchSettings) return
                        props.updateSettings({ ...props.searchSettings, send_email_updates: e.target.checked })
                    }}
                />
                {
                    (invalidNotification) && (
                        <Form.Text muted>
                            <FontAwesomeIcon className="pe-1" icon={faCircleExclamation} />
                            Notifications are only sent when preferred models are found. Please select at least one preferred model.
                        </Form.Text>
                    )
                }
            </Form.Group>
            <Row>
                <Col>
                    <Button onClick={() => setShowDeleteModal(true)} variant="outline-danger" size="sm">Delete search</Button>
                </Col>
                <Col>
                    <Form.Text className="fst-italic" style={{ float: "right" }}>
                        {props.isSaving ? "Saving..." : "Saved"}
                    </Form.Text>
                </Col>
            </Row>

            <DeleteSearchModal
                showDeleteModal={showDeleteModal}
                isDeleting={props.isDeleting}
                deleteSearch={props.deleteSearch}
                setShowDeleteModal={setShowDeleteModal}
            />
        </Form>
    )
}

export default SearchSettings
