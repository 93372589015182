import React from "react";

const CheckoutFailedPage: React.FC = () => {
    // TODO: this could be better
    return (
        <>Checkout failed...</>
    )
}

export default CheckoutFailedPage
