import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { useEffect } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

interface PostCheckoutResponse {
    user_email: string | null,
    auth0_id: string | null,
    customer: string | null,
    action: string | null
}

const CheckoutSuccessPage: React.FC = () => {
    const [searchParams] = useSearchParams()
    const [newAccountEmail, setNewAccountEmail] = React.useState<string | null>(null)
    const { loginWithRedirect, logout } = useAuth0()

    const sessionId = searchParams.get("session_id")

    useEffect(() => {
        console.log("running post-checkout flow")
        axios.post<PostCheckoutResponse>(`${process.env.REACT_APP_API_SERVER_URL}/v2/account/post_checkout`, {
            session_id: sessionId
        })
            .then(response => {
                console.log(response.data)
                const { user_email, action } = response.data

                if (action === 'ACCOUNT_CREATED') {
                    if (!user_email) {
                        throw new Error(`invalid server response for ACCOUNT_CREATED event`)
                    }

                    setNewAccountEmail(user_email)
                } else if (action === "SIGN_IN") {
                    loginWithRedirect({
                        appState: {
                            returnTo: "/account", // TODO: maybe should go to `/`?
                        },
                    })
                } else {
                    // TODO: probably should provide a more useful error here
                    logout()
                }
            })
            .catch(error => {
                console.error(error)
                logout()
            })
    }, [loginWithRedirect, logout, sessionId])

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs="12" md="6" lg="5" xl="4" className="pricing-col">
                    {
                        !newAccountEmail ? (<Spinner />) : (
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Finish setting up your InventoryWatch account
                                    </Card.Title>
                                    Check your email <mark>{newAccountEmail}</mark> for a link to finish setting up your InventoryWatch account.
                                </Card.Body>
                            </Card>
                        )
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default CheckoutSuccessPage
