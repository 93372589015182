import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavDropdown } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate, useLocation } from "react-router-dom";

import logo from "../assets/iw-logo.png"

const NavigationBar: React.FC = () => {
  const navigate = useNavigate()
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0()

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
  }

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      }
    })
  }

  const currentLocation = useLocation();
  const showComponent = currentLocation.pathname !== '/new-iphone';

  return (
    <>
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark" style={{ "borderRadius": "0.375rem" }}>
        <Container>
          <Navbar.Brand className="me-auto" href="/">
            <img
              alt="InventoryWatch Logo"
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top me-1"
            />{' '}
            InventoryWatch
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse >
            <Nav className="ms-auto">
              <Nav.Link href="/download">Download for macOS</Nav.Link>
              <Nav.Link href="/plans">Pricing</Nav.Link>
              <Nav.Link href="/support">Support</Nav.Link>
              {!isAuthenticated && (
                <Nav.Link onClick={handleLogin}>Login</Nav.Link>
              )}
              {isAuthenticated && (
                <NavDropdown title="Account" align="end" id="nav-dropdown">
                  <>
                    <NavDropdown.Item onClick={() => navigate('/account')}>
                      My Account
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>
                      Log out
                    </NavDropdown.Item>
                  </>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {!!showComponent && (
        <Card className="text-center m-3" style={{ "width": "fit-content", alignSelf: 'center' }}>
          <Card.Body>
            <Card.Title>iPhone 16 arrives on September 20th!</Card.Title>
            <Card.Text>
              Read about how and when InventoryWatch will support these new devices.
            </Card.Text>
            <Button
              variant="primary"
              onClick={e => {
                e.preventDefault()
                navigate(`/new-iphone`)
              }}
            >
              Learn more
            </Button>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default NavigationBar
