import React from "react";
import { Button, Card, ListGroup, Stack } from "react-bootstrap";

export interface PricingCardProps {
    title: string,
    isBilledAnnually: boolean,
    monthlyCost: number,
    annualCost: number,
    saleAnnualCost: number | null | undefined,
    numberOfSearches: number,
    numberOfSearchesText: string,

    monthlyStripeKey: string,
    yearlyStripeKey: string,
    auth0Id: string | undefined,
    startCheckout: (lookupKey: string, auth0Id?: string) => void,

    hasProductTrackFeature: boolean,
    hasNoAdsFeature: boolean,
    hasEmailNotificationFeature: boolean,
    hasTextNotificationsFeature: boolean
}

export const PricingCard: React.FC<PricingCardProps> = (props: PricingCardProps) => {
    const dueNow = props.isBilledAnnually ? (props.saleAnnualCost?.toFixed(2) || props.annualCost) : props.monthlyCost
    const annualPlanSavings = Math.round((((props.monthlyCost * 12) - props.annualCost) / (props.monthlyCost * 12)) * 100)

    let annualSaleSavings: number | undefined = undefined
    if (!!props.saleAnnualCost) {
        annualSaleSavings = Math.round(((props.annualCost - props.saleAnnualCost) / props.annualCost) * 100)
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Card>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                {/* Bit of hairy ternary logic to vary the card based on free-tier or paid-tier */}
                {
                    props.monthlyCost === 0 ? (
                        <h1>Free</h1>
                    ) : (
                        <Stack direction="horizontal" gap={2}>
                            <h1>{props.isBilledAnnually ? `${formatter.format((props.saleAnnualCost || props.annualCost) / 12)}` : `${formatter.format(props.monthlyCost)}`}</h1>
                            <p>per month</p>
                        </Stack>
                    )
                }

                {
                    (props.isBilledAnnually && props.monthlyCost !== 0) ? (
                        <p style={{ margin: 0 }}>
                            <em><small>12 months for <b>${props.saleAnnualCost?.toFixed(2) || props.annualCost}</b>
                                <br /> Save <mark>{annualSaleSavings || annualPlanSavings}%</mark> {!!props.saleAnnualCost ? `off the normal price! ($${props.annualCost})` : `off of monthly billing!`}</small></em>
                        </p>
                    ) : (
                        <>{(props.isBilledAnnually && props.monthlyCost === 0) && (
                            <p style={{ margin: 0, marginBottom: 24 }}><em><small>and free forever :)</small></em></p>
                        )}</>
                    )
                }
            </Card.Body>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    {props.hasProductTrackFeature ? "✅" : "🅧"}  Create <b>{props.numberOfSearchesText}</b> {props.numberOfSearches === 1 ? `search` : `searches`}
                </ListGroup.Item>
                <ListGroup.Item>
                    ✅ Download and use our <a href="/download">macOS app</a>
                </ListGroup.Item>
                <ListGroup.Item>
                    {props.hasNoAdsFeature ? "✅" : "🅧"}  No annoying ads, tracking, or spam
                </ListGroup.Item>
                <ListGroup.Item>
                    {props.monthlyCost !== 0 ? "✅" : "🅧"} Access searches on the web
                </ListGroup.Item>
                <ListGroup.Item>
                    {props.hasEmailNotificationFeature ? "✅" : "🅧"}  In-stock notifications via email
                </ListGroup.Item>
                <ListGroup.Item>
                    {props.hasTextNotificationsFeature ? "✅" : "🅧"}  In-stock notifications via text
                </ListGroup.Item>
            </ListGroup>
            <Card.Body>
                {
                    props.monthlyCost === 0 ? (
                        <a href="/download">
                            <Button variant="outline-primary">InventoryWatch for macOS</Button>
                        </a>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                onClick={() => props.startCheckout(props.isBilledAnnually ? props.yearlyStripeKey : props.monthlyStripeKey, props.auth0Id)}
                                variant="outline-primary"
                            >
                                <strong>${dueNow}</strong> | Checkout now
                            </Button>
                            {(props.isBilledAnnually && !!annualSaleSavings) && (
                                <p className="my-0 mx-2"><em><b><mark>{annualSaleSavings}%</mark> off!</b></em></p>
                            )}
                        </div>
                    )
                }
            </Card.Body>
        </Card>
    )
}
