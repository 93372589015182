import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useMyAccount, useUpdateMyAccount } from "../services/account.service";
import { AccountUpdateData } from "../types/AccountData";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { E164Number } from "libphonenumber-js/types";

interface PortalSessionResponse {
    session_url: string | null
}

export const MyAccount: React.FC = () => {
    const { logout, getAccessTokenSilently } = useAuth0()

    const accountQuery = useMyAccount()
    const accountMutation = useUpdateMyAccount()

    const [email, setEmail] = React.useState(accountQuery.data?.email || ``)
    const [formattedPhoneNumber, setFormattedPhoneNumber] = React.useState<E164Number | undefined>()
    const [enableNotifications, setEnableNotifications] = React.useState(accountQuery.data?.enable_notifications)

    React.useEffect(() => {
        setEmail(accountQuery.data?.email || ``)
        setFormattedPhoneNumber(accountQuery.data?.phone_number || ``)
        setEnableNotifications(accountQuery.data?.enable_notifications)
    }, [accountQuery.data])

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        })
    }

    const handleAccountUpdate = () => {
        if (!accountQuery.data) return

        const body: AccountUpdateData = {
            phone_number: formattedPhoneNumber,
            email: email,
            enable_notifications: enableNotifications,
        }

        accountMutation.mutate(body)
    }

    const navigateToStripePortal = async () => {
        const accessToken = await getAccessTokenSilently()

        // TODO: prob should extract this into some util function
        const response = await axios.post<PortalSessionResponse>(`${process.env.REACT_APP_API_SERVER_URL}/v2/account/create-portal-session`, {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            }
        })

        const { session_url } = response.data
        if (!session_url) {
            console.log('error! no session url')
            return
        }

        window.location.href = session_url
    }

    return (
        <div>
            <Row className="m-3 mb-0 justify-content-center">
                <Col xs="12" md="7">
                    <h2>My Account</h2>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs="12" md="6" lg="5" xl="4" className="pricing-col">

                    <Form>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                disabled={accountQuery.isLoading || accountMutation.isLoading}
                                className="mb-2"
                                type="email"
                                placeholder="appleseed@email.net"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                            <Form.Label>Phone number</Form.Label>
                            <PhoneInput
                                defaultCountry="US"
                                disabled={accountQuery.isLoading || accountMutation.isLoading}
                                placeholder="Enter phone number"
                                value={formattedPhoneNumber}
                                onChange={setFormattedPhoneNumber}
                            />

                            <Form.Text muted>
                                Your email and phone number will be used to send inventory notifications, as configured in your search settings.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="enable_notification_toggle"
                                label="Enable inventory update notifications"
                                checked={enableNotifications || false}

                                disabled={accountQuery.isLoading || accountMutation.isLoading}
                                onChange={(e) => { setEnableNotifications(e.target.checked) }}
                            />
                            <Form.Text>
                                Disable this toggle to stop all inventory update notifications.
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" disabled={accountQuery.isLoading || accountMutation.isLoading} onClick={() => { handleAccountUpdate() }}>
                            {accountQuery.isLoading || accountMutation.isLoading ? 'Loading...' : 'Save account'}
                        </Button>
                        {accountMutation.isError && (
                            <>
                                <br />
                                <Form.Text className="text-danger">
                                    Failed to save account information.
                                    Please check that your phone number is entered correctly.
                                </Form.Text>
                            </>
                        )}
                        <Form.Group className="justify-content-center">
                            <Button variant='link' onClick={handleLogout}>Logout</Button>
                            <br />

                            {/* TODO: only show if the customer has a subscription */}
                            <Button variant='link' onClick={() => { navigateToStripePortal() }}>Manage subscription and payment</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}
